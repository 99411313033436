import React, {
    Fragment,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { SnackbarAction } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash/debounce";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AppDrawer } from "~/components/Modal/AppDrawer";
import { Input } from "~/components/Input";
import { PrimaryButton } from "~/components/Button";
import { Typography } from "~/components/Typography";
import { useToastContext } from "~/state/toast";

import { mp } from "@utils/mixpanel";
import { captureError } from "@utils/sentry";
import { getErrorMessage } from "@utils/errors";
import { getCacheItem, setCacheItem, removeCacheItem, hasCacheItem } from "@utils/cache";
import { IComponentNavigateFn } from "@utils/navigate";
import { getScrollbarWidth } from "@utils/dom";

import { useLeadActions } from "@leadId/lead-state/use-lead-actions";

import type { LeadDetails } from "~/model/leads";
import type { ILeadViewActions } from "@api/leads";

const useStyles = makeStyles((theme) => ({
    modalContent: {
        height: "100%",
    },
    notesContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    notesHeader: {
        width: 450,
        padding: "0 10px",
        margin: "15px 0",
    },
    notesInput: {
        height: "100%",
        minHeight: 200,
        marginBottom: "20px",
        "& div": {
            height: "100%",
            minHeight: 200 + getScrollbarWidth(),
        },
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        width: 110,
    },
    spinnerContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    spinner: {
        color: theme.palette.text.contrast,
    },
}));

interface INotesContentProps {
    lead: LeadDetails,
    updateLeadComment: ILeadViewActions["updateLeadComment"],
    navigate: IComponentNavigateFn,
}

export const NotesContent: React.FC<INotesContentProps> = ({
    lead,
    updateLeadComment,
    navigate,
}) => {
    const leadId = lead?.id;
    const notesCacheKey = `lead_${leadId}_notes`;
    const comments = lead?.comment;
    const { addToastError, removeToastError } = useToastContext();
    const { openComments, closeComments } = useLeadActions(navigate);

    const [notes, setNotes] = useState(() => {
        if (hasCacheItem(notesCacheKey)) {
            return getCacheItem(notesCacheKey) || "";
        }
        return comments || "";
    });
    const [error, setError] = useState("");
    const [loadingSave, setLoadingSave] = useState(false);

    const [scrollbarWidth] = useState(() => getScrollbarWidth());

    const timeout = 1000; // in ms
    const isMounted = useRef(false);
    const isSaved = useRef(false);

    const styles = useStyles();

    useEffect(() => {
        isMounted.current = true;
        mp.fireEvent({
            event: "openedNotes",
            context: {
                leadId,
            },
        });
        return () => {
            isMounted.current = false;
        };
    }, [closeComments, leadId]);

    useEffect(() => {
        if (hasCacheItem(notesCacheKey)) {
            setError("Oops, we were unable to save your changes. Please try again");
        }
    }, [notesCacheKey]);

    const toastAction = useCallback((key: string): SnackbarAction => {
        return (
            <Fragment>
                <Button
                    onClick={() => {
                        openComments();
                        removeToastError(key);
                    }}
                    style={{ minWidth: "142px", color: "#fff" }}
                >
                    Review
                </Button>
            </Fragment>
        );
    }, [openComments, removeToastError]);

    const onSave = useCallback(async (newVal: string) => {
        if (isSaved.current) return;
        try {
            if (isMounted.current) isSaved.current = true;
            await updateLeadComment(newVal);
            mp.fireEvent({
                event: "savedNotes",
                context: {
                    leadId,
                },
            });
            if (isMounted.current) {
                setLoadingSave(false);
                setError("");
            }
            removeCacheItem(notesCacheKey);
        } catch (error) {
            isSaved.current = false;
            console.error(error);
            captureError(getErrorMessage(error), {
                error,
                extra: {
                    leadId,
                    oldComment: comments,
                    newComment: newVal,
                },
            });
            const errorMessage = "Something went wrong. Notes could not be saved at this time.";
            if (isMounted.current) {
                setLoadingSave(false);
                setError(errorMessage);
            } else {
                addToastError(errorMessage, {
                    persist: true,
                    action: toastAction,
                });
            }
            setCacheItem(notesCacheKey, newVal);
        }
    }, [addToastError, comments, leadId, notesCacheKey, toastAction, updateLeadComment]);

    const doSave = useMemo(() => (
        debounce(onSave, timeout)
    ), [onSave]);

    const onChange = (val: string) => {
        if (isSaved.current) isSaved.current = false;
        setNotes(val);
        doSave(val);
    };

    return (
        <AppDrawer title="Notes" onClose={closeComments} classes={{ content: styles.modalContent }}>
            <Box className={styles.notesContainer}>
                <Box className={styles.notesHeader}>
                    <Typography>
                        {"Include any notes here you want to add for this lead."}
                        <b>
                            {"Notes are automatically saved."}
                        </b>
                    </Typography>
                </Box>
                <Box className={styles.notesInput}>
                    <Input
                        id="lead-notes-textarea"
                        inputProps={{
                            style: {
                                height: "100%",
                                minHeight: 200 - scrollbarWidth,
                                padding: scrollbarWidth,
                                margin: -scrollbarWidth,
                                overflow: "auto",
                            },
                        }}
                        maxRows={32}
                        value={notes}
                        update={onChange}
                        error={error}
                        multiline
                        autoFocus
                    />
                </Box>
                <Box className={styles.buttonContainer}>
                    <PrimaryButton
                        className={styles.button}
                        onClick={() => {
                            if (!isSaved.current) setLoadingSave(true);
                            doSave.cancel();
                            onSave(notes);
                        }}
                    >
                        <span
                            style={loadingSave ? {
                                visibility: "hidden",
                            } : {}}
                        >
                            {isSaved.current ? "Saved!" : "Save"}
                        </span>
                        {loadingSave && (
                            <Box className={styles.spinnerContainer}>
                                <CircularProgress size="1.5em" className={styles.spinner} />
                            </Box>
                        )}
                    </PrimaryButton>
                </Box>
            </Box>
        </AppDrawer>
    );
};