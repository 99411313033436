export const hasCacheItem = (cacheItemKey: string) => {
    return !!(localStorage && localStorage.getItem((cacheItemKey)));
};

export const getCacheItem = (cacheItemKey: string) => {
    return localStorage && localStorage.getItem((cacheItemKey));
};

export const setCacheItem = (cacheItemKey: string, cacheItemVal: string) => {
    localStorage && localStorage.setItem(cacheItemKey, cacheItemVal);
};

export const removeCacheItem = (cacheItemKey: string) => {
    localStorage && localStorage.removeItem(cacheItemKey);
};