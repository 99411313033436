import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
    useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import yn from "yn";

import { Typography } from "~/components/Typography";

import { AllLeadCards, PrintLead } from "@leadId/index";
import { createLeadView, LeadViewComponent } from "@leadId/view";
import { useLeadActions } from "@leadId/lead-state/use-lead-actions";
import { NotesContent } from "@leadId/LeadIdViewLayout/NotesContent";

import { captureError } from "@utils/sentry";
import { parseQuery } from "@utils/url";
import { mp } from "@utils/mixpanel";
import { useLeadView } from "@api/leads";

import { LeadContent } from "./_lib";
import { ErrorType } from "./_lib";
import { useViewContext } from "~/state";

const useStyles = makeStyles((theme) => {
    // const factor = 1.875 * -1;
    return ({
        title: {
            display: "flex",
            flexFlow: "row nowrap",
        },
        error: {
            display: "flex",
            padding: "5%",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
    });
});



const LeadView: LeadViewComponent = ({
    leadId,
    location,
    navigate,
}) => {
    const [error, setError] = useState<ErrorType>();
    const fireLeadDetailEvent = useRef<boolean>(true);
    const curErr = useRef<ErrorType>();
    const styles = useStyles();

    curErr.current = error;

    const {
        lead,
        actions,
        loading,
        error: graphqlError,
    } = useLeadView(leadId, location.state?.cognitoName ?? "" );

    const {
        actions: {
            updateTitle,
        },
    } = useViewContext();

    const { getProducts } = useLeadActions(navigate);
    const products = useMemo(() => (
        getProducts(lead)
    ), [getProducts, lead]);

    const showComments = useMemo(() => {
        const { showComments } = parseQuery(location.search);
        return yn(showComments, { default: false });
    }, [location.search]);

    const content = useMemo(() => {
        switch (error) {
            case ErrorType.FetchError: {
                return (
                    <Box className={styles.error}>
                        <Typography variant="h1" component="div">
                            {"Oops... something went wrong."}
                        </Typography>
                        <Typography variant="h2" component="div">
                            {"Please, refresh browser or contact your admin."}
                        </Typography>
                    </Box>
                );
            }
            case ErrorType.NoConsumer:
            default: {
                return (
                    <LeadContent
                        cards={AllLeadCards}
                        lead={lead}
                        loading={loading}
                        error={error}
                        products={products}
                    />
                );
            }
        }
    }, [error, lead, loading, products, styles]);

    useEffect(() => {
        if (!loading) {
            if (!lead || graphqlError) {
                if (graphqlError) {
                    console.error(graphqlError);
                    captureError(graphqlError, { extra: { leadId } });
                    setError(ErrorType.FetchError);
                } else {
                    updateTitle("");
                    setError(ErrorType.NoConsumer);
                }
            } else {
                setError(undefined);
                const fullName = [lead?.firstName, lead?.lastName].filter(Boolean).join(" ");
                updateTitle((
                    <div className={styles.title}>
                        {fullName}
                        {!curErr.current && (
                            <PrintLead
                                content={(
                                    <LeadContent
                                        cards={AllLeadCards}
                                        lead={lead}
                                        print
                                    />
                                )}
                                leadId={leadId}
                                fullName={fullName}
                                date={lead.createdAt}
                                products={products}
                            />
                        )}
                    </div>
                ));

                if (fireLeadDetailEvent.current) {
                    mp.fireEvent({
                        event: "openedLeadDetail",
                        context: {
                            leadId: leadId,
                            products,
                        },
                    });
                    fireLeadDetailEvent.current = false;
                }
            }
        } else {
            updateTitle("Loading...");
            setError(undefined);
        }
    }, [graphqlError, lead, leadId, loading, products, styles, updateTitle]);

    return (
        <Fragment>
            {content}
            {showComments && !loading && !error && (
                <NotesContent
                    lead={lead}
                    updateLeadComment={actions.updateLeadComment}
                    navigate={navigate}
                />
            )}
        </Fragment>
    );
};

export default createLeadView(LeadView, {});